<template>
  <section class="container is-max-desktop mt-5">
    <iframe
      data-tally-src="https://tally.so/embed/w27PMg?alignLeft=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="100"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Vendor Registration"
    ></iframe>
    <script type="application/javascript">
      var d = document,
        w = "https://tally.so/widgets/embed.js",
        v = function () {
          "undefined" != typeof Tally
            ? Tally.loadEmbeds()
            : d
                .querySelectorAll("iframe[data-tally-src]:not([src])")
                .forEach(function (e) {
                  e.src = e.dataset.tallySrc;
                });
        };
      if ("undefined" != typeof Tally) v();
      else if (d.querySelector('script[src="' + w + '"]') == null) {
        var s = d.createElement("script");
        (s.src = w), (s.onload = v), (s.onerror = v), d.body.appendChild(s);
      }
    </script>
  </section>
</template>

<script>
import { db } from "../firebase";
import { generateRandomCode } from "../helpers";
export default {
  data() {
    return {
      formData: null,
      generateRandomCode,
    };
  },
  methods: {
    handleTallyMessage(event) {
      if (event.origin !== "https://tally.so") return;

      try {
        if (event.data.includes("Tally.FormSubmitted")) {
          const parsedData = JSON.parse(event.data);
          const fields = parsedData.payload.fields;

          this.formData = {
            id: parsedData.payload.id,
            name: fields[0].answer.raw,
            email: fields[1].answer.raw,
            phoneNumber: fields[2].answer.raw,
            role: fields[3].answer.value,
            idCard: [fields[4].answer.raw[0].url, fields[4].answer.raw[1].url],
            salesHistory: [],
          };
          this.addVendorToDb();
        }
      } catch (error) {
        console.error("Error parsing Tally form data:", error);
      }
    },
    addVendorToDb() {
      db.firestore()
        .collection("vendors")
        .doc(this.formData.id)
        .set(this.formData)
        .catch((error) => {
          console.log(error);
        });

      this.createRandomCode();
    },
    createRandomCode() {
      const code = generateRandomCode(7);
      db.firestore()
        .collection("codes")
        .doc(code)
        .set({ code: code })
        .then(() => {
          this.sendRegistrationCodeToVendor(code);
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 10000,
            message: error,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    sendRegistrationCodeToVendor(code) {
      const data = {
        email: this.formData.email,
        vendor_id: this.formData.id,
        registrationCode: code,
      };
      fetch(
        `${process.env.VUE_APP_API_URL}/send/mail/vendor-registration-password`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
        .then((response) => {
          if (response.status !== 200) return false;
          this.$buefy.toast.open({
            duration: 10000,
            message:
              "An email with a registration code has been sent to the vendor",
            position: "is-bottom",
            type: "is-success",
          });
          /*  if (window.history.length) {
            this.$router.go(-1);
            return;
          } */
        })
        .catch(() => {
          this.$buefy.toast.open({
            duration: 10000,
            message:
              "There has been an error sending the registration code to the vendor. If this error persists contact support.",
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
  mounted() {
    window.addEventListener("message", this.handleTallyMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleTallyMessage);
  },
};
</script>
